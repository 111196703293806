.landing-button {
    box-shadow: 0px 1px 12.7px 0px #82828233;
    border: 1px solid #FFB4C8 !important;
    width: 100%;
    height: 56px;
    border-radius: 16px !important;
}

.success-btn {
    width: 100%;
    height: 48px;
    border-radius: 16px !important;
    background: #0C9835 !important;
    color: white !important;
}

.primary-btn {
    width: 100%;
    height: 48px;
    border-radius: 16px !important;
    background: var(--primary-clr) !important;
    color: white !important;
    border: none !important;
}

.primary-btn:disabled {
    background-color: #DADADA !important;
    color: #828282 !important;
    border: none !important;

}