:root{
    --primary-clr : #BC3357;
    --primary-black:#263238;
    --white:#fff;
}
.primary-black{
    color: var(--primary-black);
}
.primary-clr{
    color: var(--primary-clr);
}
.white-bg{
    background-color: var(--white) !important;
}