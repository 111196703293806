ion-content {
  height: 100vh;
  overflow: auto;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-bottom {
  border-bottom: 1px solid #DADADA;
}

.pointer {
  cursor: pointer;
}

.ion-header {
  position: sticky;
  top: 0px;
  background: white;
}

.w-fitcontent {
  width: fit-content;
}

.line-height {
  line-height: 1;
}

.limit-line-length-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.limit-line-length-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title-text {
  position: relative;
  display: inline-block;
}

.title-text:before {
  content: "";
  border: 1px solid #E3E3E3;
  position: absolute;
  width: 65%;
  top: 12px;
  left: -97%;
}

.title-text:after {
  content: "";
  border: 1px solid #E3E3E3;
  width: 65%;
  position: absolute;
  top: 12px;
  right: -97%;
}

.custom-tooltips {
  position: fixed;
  z-index: 2;
  opacity: 0;
  display: none;
  background: var(--primary-black);
  border-radius: 16px;
  box-shadow: 0px -1px 17px 0px #00000040;
  color: white;
  padding: 16px;
  width: 90%;
  left: 0px;
  right: 0px;
  margin: auto;
  transition: all 0.3s;
}

.down-arrow {
  width: 0;
  z-index: 2;
  height: 0;
  display: none;
  position: fixed;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid var(--primary-black);
}

.skeleton-loader {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0));
    animation: shimmer 1s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.address-radio-group {

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
    line-height: 2.2;
    cursor: pointer;
    background: white;
    border-radius: 1em;
    color: #262730;
    font-size: 14px;
    border: 1px solid #263238 !important;
  }

  label:before {
    box-sizing: border-box;
    content: " ";
    position: absolute;
    top: 0.4em;
    left: 0;
    display: block;
    width: 1.2em;
    height: 1.2em;
    // border: 1px solid #fe5069;
    border-radius: 0.25em;
    // z-index: -1;
  }

  input[type="radio"]+label::before {
    border-radius: 1em;
  }

  /* Checked */
  input[type="checkbox"]:checked+label,
  input[type="radio"]:checked+label {
    // padding-left: 1em;
    // color: #fe5069;
    color: var(--primary-clr);
    background: #FFE5EC;
    img{
      filter: invert(28%) sepia(57%) saturate(2033%) hue-rotate(316deg) brightness(84%) contrast(90%);
    }
    border: 1px solid var(--primary-clr) !important;
    border-radius: 1em;
    font-size: 14px;
  }

  input[type="checkbox"]:checked+label:before,
  input[type="radio"]:checked+label:before {
    top: 0;
    width: 100%;
    height: 2em;
    // background: #fe5069;
  }

  /* Transition */
  label,
  label::before {
    -webkit-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
}

ion-modal#example-modal {
  --width: 90%;
  ;
  --height: fit-content;
}

ion-modal#example-modal .wrapper {
  margin-bottom: 10px;
}

#snackbar {
  visibility: hidden;
  width: 100%;
  background-color: var(--primary-clr);
  min-width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px;
  font-weight: 500;
  position: fixed !important;
  z-index: 1 !important;
  top: 56px;
  font-size: 12px !important;

}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    transform: translateY(0px);
    opacity: 1;
  }

  to {
    transform: translateY(-100px);
    opacity: 0;
  }
}