// /** Regular */
// @font-face {
//     font-family: "Inter";
//     font-weight: 400;
//     src: url("../font/Inter-Regular.ttf");
// }

// @font-face {
//     font-family: "Inter";
//     font-weight: 500;
//     src: url("../font/Inter-Medium.ttf");
// }

// @font-face {
//     font-family: "Inter";
//     font-weight: 600;
//     src: url("../font/Inter-SemiBold.ttf");
// }
// @font-face {
//     font-family: "Inter";
//     font-weight: 700;
//     src: url("../font/Inter-Bold.ttf");
// }

// @font-face {
//     font-family: "Inter";
//     font-weight: 800;
//     src: url("../font/Inter-ExtraBold.ttf");
// }

@font-face {
  font-family: "Avenir-Book";
  font-weight: 300;
  src: url("../font/Avenir-Book.ttf");
}
@font-face {
  font-family: "Avenir-Roman";
  font-weight: 400;
  src: url("../font/Avenir-Roman.ttf");
}
@font-face {
  font-family: "Avenir-Medium";
  font-weight: 500;
  src: url("../font/Avenir-Medium.ttf");
}
@font-face {
  font-family: "Avenir-Heavy";
  font-weight: 600;
  src: url("../font/Avenir-Heavy.ttf");
}
@font-face {
  font-family: "Avenir-Black";
  font-weight: 800;
  src: url("../font/Avenir-Black.ttf");
}


.sitefontlight {
  font-family: "Avenir-Book" !important;
}

.sitefontnormal {
  font-family: "Avenir-Roman" !important;
}

.sitefontmedium {
  font-family: "Avenir-Medium" !important;
}

.sitefontbold {
  font-family: "Avenir-Heavy" !important;
}

.sitefontextrabold {
  font-family: "Avenir-Black" !important;
}


