/* You can add global styles to this file, and also import other style files */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/styles/main-root";

/* RTL specific styles */
:host-context([dir="rtl"]) .ion-float-start {
    float: right;
}

:host-context([dir="rtl"]) .ion-float-end {
    float: left;
}

/* Form Floating Label */
.form-floating {
    position: relative;
}

.form-floating>*~label {
    margin-top: -1.5em;
}